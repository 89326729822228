<template>
  <BasePageSection full-width full-width-content>
    <VLayout row py-4 wrap justify-center>
      <VFlex xs12 sm5 offset-sm1 md5 offset-md1 xl4 offset-xl2 pa-4>
        <BasePageContent
          content-key="contact_form_title"
          default-content="Send Us a Message"
          tag-class="header-3 form-greeting bluegiant-blue--text pb-4 mb-4"
          tag="h2"
        />

        <BasePageContent
          content-key="contact_form_description"
          default-content="If you have any inquiries or comments, please use this form to
          contact us.

Catering and private events are available. Please contact for further
          information.
          "
          tag="p"
        />
      </VFlex>
      <VFlex xs12 sm5 xl4 pa-4>
        <ContactForm
          color="bluegiant-blue"
          class="text-xs-center bluegiant-contact-form"
          field-color="bluegiant-red"
          :message-component="customMsgComponent"
        >
          <template #success-msg="{ dismiss }">
            <h1 class="grey--text">Thank you.</h1>
            <p class="r-title">We'll get back to you soon.</p>
            <BaseButton
              class="ml-0"
              color="bluegiant-blue lighten-2"
              small
              @click="dismiss"
              >Great!</BaseButton
            >
          </template>
          <template #submit-btn>
            <BaseButton color="#0b1b8f" class="jumbo" type="submit"
              >Send</BaseButton
            >
          </template>
        </ContactForm>
      </VFlex>
    </VLayout>
  </BasePageSection>
</template>

<script>
import ContactForm from '@components/ContactForm'
import { VTextarea } from '@vuetify'
export default {
  name: 'ContactSection',
  components: {
    ContactForm,
    // eslint-disable-next-line
    VTextarea,
  },
  data() {
    return {
      customMsgComponent: VTextarea,
    }
  },
}
</script>

<style lang="scss">
@import '@design';

.bluegiant-contact-form {
  .v-text-field .v-input__control .v-input__slot {
    &::before,
    &::after {
      border-color: $color-bluegiant-red;
      transform: scaleX(1);
    }
  }
}
</style>
